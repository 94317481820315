var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "vue-horizontal",
        { attrs: { button: false, displacement: 0.7, snap: "center" } },
        _vm._l(_vm.navigation, function (i) {
          return _c("section", { key: i.id, staticClass: "section" }, [
            _c(
              "button",
              {
                staticClass: "button",
                class: { button__active: _vm.currentItemId === i.id },
                attrs: { plain: "" },
                on: {
                  click: function ($event) {
                    return _vm.handleClick(i.id)
                  },
                },
              },
              [_vm._v(" " + _vm._s(i.title) + " ")]
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./SupervisorButtons.vue?vue&type=template&id=252430bd&scoped=true&"
import script from "./SupervisorButtons.vue?vue&type=script&lang=js&"
export * from "./SupervisorButtons.vue?vue&type=script&lang=js&"
import style0 from "./SupervisorButtons.vue?vue&type=style&index=0&id=252430bd&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "252430bd",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('252430bd')) {
      api.createRecord('252430bd', component.options)
    } else {
      api.reload('252430bd', component.options)
    }
    module.hot.accept("./SupervisorButtons.vue?vue&type=template&id=252430bd&scoped=true&", function () {
      api.rerender('252430bd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/supervisor/components/SupervisorButtons.vue"
export default component.exports
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
      staticClass: "samples",
    },
    [
      _c(
        "el-button",
        {
          staticClass: "samples__button",
          attrs: { type: "primary" },
          on: { click: _vm.applySupervisorSamples },
        },
        [_vm._v(" Шаблон правил вызова ")]
      ),
      _c(
        "el-tooltip",
        {
          attrs: {
            content: "Настройка шаблона правил вызова",
            placement: "bottom-start",
            "popper-class": "samples__tooltip",
          },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "samples__button mod-cog",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("samples")
                },
              },
            },
            [_c("IconSettings")],
            1
          ),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "samples__button",
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.$emit("archive")
            },
          },
        },
        [_vm._v(" Архив ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div v-loading="isLoading">
    <div class="header--wrapper header--wrapper_toolbar">
      <div class="container">
        <el-row
          type="flex"
          :gutter="20"
          justify="space-between"
          align="middle"
          class="toolbar flex-w"
        >
          <el-col v-if="!isMobile" :span="10" :xs="24">
            <SamplesHandleButtons
              v-if="isHomePage && !isViewer"
              @apply="applySamplesModal"
              @samples="routeTo('Samples')"
            />
            <div v-else-if="isSamplesPage || isSupervisorsChildPage">
              <el-button
                type="info"
                plain
                icon="el-icon-arrow-left"
                class="header-back-button"
                @click="goBack"
              >
                Назад
              </el-button>
            </div>
            <SupervisorButtons
              v-else-if="isSupervisorPage"
              @samples="routeTo('AdminSupervisorSamples')"
              @archive="routeTo('AdminSupervisorArchive')"
            />
            <ExtraMenu v-else :reset-extra-menu-title="resetExtraMenuTitle" />
          </el-col>

          <el-col v-if="!isMobile" :span="7" :xs="24">
            <add-quota-button
              v-if="isQuotaButtonShown"
              :is-terminal-id="Boolean(currentTerminal)"
            />
            <div v-if="isSupervisorPage" class="search input-text--search">
              <iq-button
                icon="IconRefresh"
                icon-height="14px"
                icon-width="14px"
                class="m-l-10"
                @onClick="handleClickRefresh"
              >
                Обновить страницу
              </iq-button>
            </div>
          </el-col>

          <el-col v-if="!isMobile" :span="7">
            <IqSelect
              v-model="currentTerminal"
              class="w-100p max-w-300 max-w-t-100p"
              :options="terminalOptions"
              :disabled="terminalOptions.length === 1"
              placeholder="Выберите город"
            />
          </el-col>
        </el-row>
      </div>

      <div v-if="isMobile" class="containerMobile">
        <add-quota-button
          v-if="isQuotaButtonShown"
          :is-terminal-id="Boolean(currentTerminal)"
        />
        <add-supervisor-quota-button
          v-if="isSupervisorPage || isSupervisorSamplesPage"
        />
      </div>
    </div>

    <div v-if="isMobile">
      <SwitcherPage />
      <div style="padding: 0 16px; width: 100%; margin-bottom: 20px">
        <IqSelect
          v-model="currentTerminal"
          class="w-100p max-w-300 max-w-t-100p"
          :options="terminalOptions"
          :disabled="terminalOptions.length === 1"
          placeholder="Выберите город"
        />
      </div>
      <SamplesHandleButtons
        v-if="isHomePage && !isViewer"
        @apply="applySamplesModal"
        @samples="routeTo('Samples')"
      />
      <SupervisorButtons
        v-if="isSupervisorPage"
        @samples="routeTo('AdminSupervisorSamples')"
        @archive="routeTo('AdminSupervisorArchive')"
      />
      <div v-else-if="isSamplesPage || isSupervisorsChildPage">
        <el-button
          type="info"
          plain
          icon="el-icon-arrow-left"
          class="header-back-button"
          @click="goBack"
        >
          Назад
        </el-button>
      </div>
    </div>
    <DialogApplySamples />
  </div>
</template>

<script>
import {
  GET_IS_MOBILE,
  GET_TERMINAL_CURRENT_ID,
  GET_TERMINAL_LIST,
  GET_UPDATE_ACTION,
  SET_TERMINAL,
} from '@/store/actions'
import { QUOTA_APPLY_SAMPLES } from '@/constants/dialogs'
import { getUserProfile, replaceCurrentTerminal } from '@/core'
import { mapGetters, mapMutations } from 'vuex'
import AddQuotaButton from '@/UI/form/buttons/AddQuotaButton.vue'
import AddSupervisorQuotaButton from '@/views/supervisor/components/AddSupervisorQuotaButton.vue'
import Api from '@/api'
import DialogApplySamples from '@/components/layouts/default/header/toolbar/components/dialogs/DialogApplySamples.vue'
import ExtraMenu from '@/components/layouts/default/header/toolbar/components/extraMenu/ExtraMenu'
import IqButton from '@/views/ui/components/buttons/IqButton.vue'
import IqSelect from '@/views/ui/components/select/IqSelect'
import SamplesHandleButtons from './components/SamplesHandleButtons.vue'
import SupervisorButtons from '@/views/supervisor/components/SupervisorButtons.vue'
import SwitcherPage from '@/components/layouts/default/header/toolbar/components/extraMenu/SwitcherPage'

export default {
  name: 'Toolbar',
  components: {
    IqButton,
    SwitcherPage,
    IqSelect,
    ExtraMenu,
    AddQuotaButton,
    AddSupervisorQuotaButton,
    SamplesHandleButtons,
    SupervisorButtons,
    DialogApplySamples,
  },
  props: { resetExtraMenuTitle: { type: Number, required: true } },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      refresh: GET_UPDATE_ACTION,
      allTerminals: GET_TERMINAL_LIST,
      currentTerminalId: GET_TERMINAL_CURRENT_ID,
      isMobile: GET_IS_MOBILE,
    }),
    viewerTerminals() {
      return getUserProfile().unloads
    },
    terminalOptions() {
      return this.isViewer ? this.viewerTerminals : this.allTerminals
    },
    currentTerminal: {
      get() {
        return this.currentTerminalId
      },
      set(newVal) {
        let newTerminal = this.terminalOptions.find(t => t.id === newVal)

        replaceCurrentTerminal(newTerminal)
        this.setTerminal(newTerminal)
      },
    },
    isHomePage() {
      return this.$route.name === 'Home'
    },
    isSamplesPage() {
      return this.$route.name === 'Samples'
    },
    isSupervisorPage() {
      return (
        this.$route.name === 'AdminSupervisor' ||
        this.$route.name === 'AdminSupervisorTable'
      )
    },
    isSupervisorSamplesPage() {
      return this.$route.name === 'AdminSupervisorSamples'
    },
    isSupervisorsChildPage() {
      return (
        this.$route.name === 'AdminSupervisorArchive' ||
        this.$route.name === 'AdminSupervisorSamples'
      )
    },
    isQuotaButtonShown() {
      return (
        (this.isHomePage || this.isSamplesPage) &&
        !this.isViewer &&
        !this.isSupport
      )
    },
  },
  methods: {
    ...mapMutations({ setTerminal: SET_TERMINAL }),
    routeTo(name) {
      this.$router.push({ name })
    },
    goBack() {
      let name = this.isSamplesPage
        ? 'Home'
        : localStorage.getItem('supervisor-page-format')

      this.routeTo(name)
    },
    async applySamplesModal() {
      // перед выводом модалки проверяем, есть ли у терминала сохраненные шаблоны
      try {
        this.isLoading = true
        let { data } = await Api.quotaSamples.get({
          unload_id: this.currentTerminalId,
        })

        this.setDialog({
          name: QUOTA_APPLY_SAMPLES,
          visible: true,
          data: data.length,
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.isLoading = false
      }
    },
    handleClickRefresh() {
      if (typeof this.refresh === 'function') {
        this.refresh()
      }
    },
  },
}
</script>
<style lang="sass" scoped>
.containerMobile
  position: fixed
  bottom: 0
  left: 0
  right: 0
  padding: 16px
  background-color: #fff
  display: flex
  align-items: center
  justify-content: center
  z-index: 100
  :deep(.search)
    justify-content: center
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "samples" },
    [
      _c(
        "el-button",
        {
          staticClass: "samples__button",
          attrs: { type: "primary" },
          on: {
            click: function ($event) {
              return _vm.$emit("apply")
            },
          },
        },
        [_vm._v(" Шаблон суточных квот ")]
      ),
      _c(
        "el-tooltip",
        {
          attrs: {
            content: "Настройка шаблона суточных квот",
            placement: "bottom-start",
            "popper-class": "samples__tooltip",
          },
        },
        [
          _c(
            "el-button",
            {
              staticClass: "samples__button mod-cog",
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.$emit("samples")
                },
              },
            },
            [_c("IconSettings")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
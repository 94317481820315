var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.isLoading,
          expression: "isLoading",
        },
      ],
    },
    [
      _c("div", { staticClass: "header--wrapper header--wrapper_toolbar" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "el-row",
              {
                staticClass: "toolbar flex-w",
                attrs: {
                  type: "flex",
                  gutter: 20,
                  justify: "space-between",
                  align: "middle",
                },
              },
              [
                !_vm.isMobile
                  ? _c(
                      "el-col",
                      { attrs: { span: 10, xs: 24 } },
                      [
                        _vm.isHomePage && !_vm.isViewer
                          ? _c("SamplesHandleButtons", {
                              on: {
                                apply: _vm.applySamplesModal,
                                samples: function ($event) {
                                  return _vm.routeTo("Samples")
                                },
                              },
                            })
                          : _vm.isSamplesPage || _vm.isSupervisorsChildPage
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "header-back-button",
                                    attrs: {
                                      type: "info",
                                      plain: "",
                                      icon: "el-icon-arrow-left",
                                    },
                                    on: { click: _vm.goBack },
                                  },
                                  [_vm._v(" Назад ")]
                                ),
                              ],
                              1
                            )
                          : _vm.isSupervisorPage
                          ? _c("SupervisorButtons", {
                              on: {
                                samples: function ($event) {
                                  return _vm.routeTo("AdminSupervisorSamples")
                                },
                                archive: function ($event) {
                                  return _vm.routeTo("AdminSupervisorArchive")
                                },
                              },
                            })
                          : _c("ExtraMenu", {
                              attrs: {
                                "reset-extra-menu-title":
                                  _vm.resetExtraMenuTitle,
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isMobile
                  ? _c(
                      "el-col",
                      { attrs: { span: 7, xs: 24 } },
                      [
                        _vm.isQuotaButtonShown
                          ? _c("add-quota-button", {
                              attrs: {
                                "is-terminal-id": Boolean(_vm.currentTerminal),
                              },
                            })
                          : _vm._e(),
                        _vm.isSupervisorPage
                          ? _c(
                              "div",
                              { staticClass: "search input-text--search" },
                              [
                                _c(
                                  "iq-button",
                                  {
                                    staticClass: "m-l-10",
                                    attrs: {
                                      icon: "IconRefresh",
                                      "icon-height": "14px",
                                      "icon-width": "14px",
                                    },
                                    on: { onClick: _vm.handleClickRefresh },
                                  },
                                  [_vm._v(" Обновить страницу ")]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isMobile
                  ? _c(
                      "el-col",
                      { attrs: { span: 7 } },
                      [
                        _c("IqSelect", {
                          staticClass: "w-100p max-w-300 max-w-t-100p",
                          attrs: {
                            options: _vm.terminalOptions,
                            disabled: _vm.terminalOptions.length === 1,
                            placeholder: "Выберите город",
                          },
                          model: {
                            value: _vm.currentTerminal,
                            callback: function ($$v) {
                              _vm.currentTerminal = $$v
                            },
                            expression: "currentTerminal",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm.isMobile
          ? _c(
              "div",
              { staticClass: "containerMobile" },
              [
                _vm.isQuotaButtonShown
                  ? _c("add-quota-button", {
                      attrs: { "is-terminal-id": Boolean(_vm.currentTerminal) },
                    })
                  : _vm._e(),
                _vm.isSupervisorPage || _vm.isSupervisorSamplesPage
                  ? _c("add-supervisor-quota-button")
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.isMobile
        ? _c(
            "div",
            [
              _c("SwitcherPage"),
              _c(
                "div",
                {
                  staticStyle: {
                    padding: "0 16px",
                    width: "100%",
                    "margin-bottom": "20px",
                  },
                },
                [
                  _c("IqSelect", {
                    staticClass: "w-100p max-w-300 max-w-t-100p",
                    attrs: {
                      options: _vm.terminalOptions,
                      disabled: _vm.terminalOptions.length === 1,
                      placeholder: "Выберите город",
                    },
                    model: {
                      value: _vm.currentTerminal,
                      callback: function ($$v) {
                        _vm.currentTerminal = $$v
                      },
                      expression: "currentTerminal",
                    },
                  }),
                ],
                1
              ),
              _vm.isHomePage && !_vm.isViewer
                ? _c("SamplesHandleButtons", {
                    on: {
                      apply: _vm.applySamplesModal,
                      samples: function ($event) {
                        return _vm.routeTo("Samples")
                      },
                    },
                  })
                : _vm._e(),
              _vm.isSupervisorPage
                ? _c("SupervisorButtons", {
                    on: {
                      samples: function ($event) {
                        return _vm.routeTo("AdminSupervisorSamples")
                      },
                      archive: function ($event) {
                        return _vm.routeTo("AdminSupervisorArchive")
                      },
                    },
                  })
                : _vm.isSamplesPage || _vm.isSupervisorsChildPage
                ? _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "header-back-button",
                          attrs: {
                            type: "info",
                            plain: "",
                            icon: "el-icon-arrow-left",
                          },
                          on: { click: _vm.goBack },
                        },
                        [_vm._v(" Назад ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("DialogApplySamples"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
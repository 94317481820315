<template>
  <div v-loading="isLoading" class="samples">
    <el-button
      type="primary"
      class="samples__button"
      @click="applySupervisorSamples"
    >
      Шаблон правил вызова
    </el-button>
    <el-tooltip
      content="Настройка шаблона правил вызова"
      placement="bottom-start"
      popper-class="samples__tooltip"
    >
      <el-button
        type="primary"
        class="samples__button mod-cog"
        @click="$emit('samples')"
      >
        <IconSettings />
      </el-button>
    </el-tooltip>
    <el-button type="primary" class="samples__button" @click="$emit('archive')">
      Архив
    </el-button>
  </div>
</template>
<script>
import { GET_TERMINAL_CURRENT_ID } from '@/store/actions'
import { SUPERVISOR_APPLY_SAMPLES } from '@/constants/dialogs'
import { mapGetters } from 'vuex'
import Api from '@/api'
import IconSettings from '@/components/icons/IconSettings.vue'
export default {
  name: 'SamplesHandleButtons',
  components: { IconSettings },
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({ currentTerminalId: GET_TERMINAL_CURRENT_ID }),
  },
  methods: {
    async applySupervisorSamples() {
      try {
        this.isLoading = true
        let { data } = await Api.supervisor.getSupervisorSamples({
          unload_id: this.currentTerminalId,
        })

        this.setDialog({
          name: SUPERVISOR_APPLY_SAMPLES,
          data: data.length,
          visible: true,
        })
      } catch (e) {
        console.log(e.response.data)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.samples
  display: flex
  padding-left: 24px
  @media(max-width: 1199px)
    padding-left: 16px
  &__button
    display: flex
    justify-content: center
    align-items: center
    height: 38px
    padding: 0 20px
    background: $color-blue-light
    @media(max-width: 420px)
      padding: 0 15px
    &.mod-cog
      padding-top: 3px
    &.mod-back
      color: $main-font
      background: $backgroundGrayTwo !important
      &:hover
        color: unset
    &:hover
      opacity: 0.9
</style>

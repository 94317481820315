var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "search input-text--search" },
    [
      _c("transition", { attrs: { name: "el-fade-in-linear" } }, [
        _vm.isShowBtn
          ? _c(
              "div",
              { class: [_vm.buttonClass, { buttonMargin: _vm.buttonMargin }] },
              [
                _c(
                  "iq-button",
                  {
                    attrs: {
                      icon: "DriverPlus",
                      color: "main-dark",
                      size: _vm.buttonSize,
                      disabled: !_vm.isTerminalId,
                    },
                    on: { onClick: _vm.handleAddDay },
                  },
                  [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="container">
    <vue-horizontal :button="false" :displacement="0.7" snap="center">
      <section v-for="i in navigation" :key="i.id" class="section">
        <button
          plain
          class="button"
          :class="{ button__active: currentItemId === i.id }"
          @click="handleClick(i.id)"
        >
          {{ i.title }}
        </button>
      </section>
    </vue-horizontal>
  </div>
</template>

<script>
import { GET_TERMINAL_CURRENT_ID_SLUG } from '@/store/actions'
import { mapGetters } from 'vuex'
import { navigationList, navigationListStep } from '@/constants/navigaiton'

export default {
  name: 'SwitcherPage',
  props: {},

  data() {
    return {
      currentItemId: 0,
      navigationList,
      navigationListStep,
      title: '',
    }
  },

  computed: {
    ...mapGetters({
      terminalSlug: GET_TERMINAL_CURRENT_ID_SLUG,
    }),
    navigation() {
      return this.terminalSlug === 'step'
        ? this.navigationListStep.find(
            item => item.route === this.getParentPageRoute,
          )?.children || []
        : this.navigationList.find(
            item => item.route === this.getParentPageRoute,
          )?.children || []
    },
  },
  watch: {
    navigation: {
      handler(val) {
        const currentItem = val.filter(
          item => this.$route.name === item.name,
        )[0]

        if (this.$route.name.includes('Control-')) {
          currentItem === undefined
            ? this.$router.push({ name: 'Control-terminal' })
            : (this.currentItemId = currentItem.id)
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleClick(id) {
      const currentItem = this.navigation.filter(item => item.id === id)[0]

      const { name, dialog } = currentItem

      if (name) {
        this.$router.push({ name })
        this.title = currentItem.title
      }
      if (dialog) {
        this.setDialog({
          name: dialog,
          visible: true,
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.container
  padding: 14px 14px 14px 16px
  width: 100%
  ::v-deep .v-hl-container
    flex-wrap: wrap
.section
  padding-right: 8px
  padding-bottom: 14px
.button
  background: white
  box-sizing: border-box
  border-radius: 4px
  color: #000
  border: 1px solid #E4E7ED
  font-family: Roboto, sans-serif
  font-style: normal
  font-weight: 500
  font-size: 12px
  line-height: 18px
  padding: 8px 13px!important
  transition: all .3s ease
  &__active
    border: 1px solid #C8E3F2
    background: #D9ECFF
</style>

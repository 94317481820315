<template>
  <div class="samples">
    <el-button type="primary" class="samples__button" @click="$emit('apply')">
      Шаблон суточных квот
    </el-button>
    <el-tooltip
      content="Настройка шаблона суточных квот"
      placement="bottom-start"
      popper-class="samples__tooltip"
    >
      <el-button
        type="primary"
        class="samples__button mod-cog"
        @click="$emit('samples')"
      >
        <IconSettings />
      </el-button>
    </el-tooltip>
  </div>
</template>
<script>
import IconSettings from '@/components/icons/IconSettings.vue'

export default {
  name: 'SamplesHandleButtons',
  components: { IconSettings },
}
</script>

<style lang="sass" scoped>
.samples
  display: flex
  padding-left: 24px
  @media(max-width: 1199px)
    padding-left: 16px
  &__button
    display: flex
    justify-content: center
    align-items: center
    height: 38px
    padding: 0 20px
    background: $color-blue-light
    &.mod-cog
      padding-top: 3px
    &.mod-back
      color: $main-font
      background: $backgroundGrayTwo !important
      &:hover
        color: unset
    &:hover
      opacity: 0.9
</style>
